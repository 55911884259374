import { Modal, ModalFuncProps } from 'antd';
import "./index.less";

const Confirm = (props: ModalFuncProps) => {
  return (
    Modal.confirm({
      okText: '确认',
      cancelText: '取消',
      className: 'confirm',
      cancelButtonProps: { className: 'blue-btn' },
      okButtonProps: { className: 'yellow-btn' },
      closable: true,
      ...props
    })
  );
};

export default Confirm
