/*消息的枚举类型*/
export enum HeadType {
  LOGIN_REQUEST = 0, // 登录请求，则请求时设置Message的 LoginRequest loginRequest 字段的值
  LOGIN_RESPONSE = 1, // 登录响应，则响应Message消息的 MessageResponse loginResponse 字段有值
  HEART_BEAT = 2, // 心跳请求，则请求时设置Message消息的 MessageHeartBeat heartBeat 字段的值
  MESSAGE_REQUEST = 3, //IM聊天发送消息，预留
  MESSAGE_RESPONSE = 4, // IM聊天响应消息，预留
  MESSAGE_NOTIFICATION = 5, // 通知响应消息，则响应Message消息的 MessageNotification notification 字段有值
  RACE_USER_ONLINE = 6, // 比赛用户上线消息，则响应Message消息的 RaceUserOnlineNotification raceUserOnline 字段有值
  RACE_USER_OFFLINE = 7, // 比赛用户下线消息，则响应Message消息的 RaceUserOfflineNotification raceUserOffline 字段有值
  ROW_DATA_SUBMIT_REQUEST = 8, // 客户端提交运动数据消息，则请求时设置Message的 RowDataSubmitRequest rowDataSubmitRequest 字段的值
  ROW_DATA_SUBMIT_RESPONSE = 9, // 运动数据提交的响应消息，则响应Message消息的 MessageResponse rowDataSubmitResponse 字段有值，客户端可以根据返回值判断是否提交成功
  LIVE_ROW_DATA_RESPONSE = 10, // 实时运动数据响应消息，则响应Message消息的 LiveRowDataResponse liveRowDataResponse 字段有值
  PREPARE_REQUEST = 11, // 准备或取消准备的请求消息，则请求时设置Message的 PrepareRequest prepareRequest 字段的值
  PREPARE_RESPONSE = 12, // 准备或取消准备的响应消息，则响应Message消息的 MessageResponse prepareResponse 字段有值
  PREPARE_RACE_CMD = 13, // 准备比赛命令响应消息，则响应Message消息的 PrepareRaceCmd prepareRaceCmd 字段有值，客户端接收到后，执行指令将赛事进入准备状态
  START_RACE_CMD = 14, // 开始比赛命令响应消息，则响应Message消息的 StartRaceCmd startRaceCmd 字段有值，客户端接收到后，发送指令给pm，开始比赛
  STOP_RACE_CMD = 15, // 强制结束比赛命令响应消息，则响应Message消息的 StopRaceCmd stopRaceCmd 字段有值，客户端接收到后，发送指令给pm，执行结束比赛指令
  RANKINGS_RESPONSE = 16, // 返回的整个排行榜数据响应消息，则响应Message消息的 RankingsResponse rankingsResponse 字段有值
  PM_AUTH_REQUEST = 17, // 请求获取pm认证的密码，则请求Message消息的 PmAuthRequest pm_auth_request 字段设值
  PM_AUTH_RESPONSE = 18, // 请求获取pm认证的密码的响应，则响应Message消息的 PmAuthResponse pm_auth_response 字段有值
}

/*请求获取pm的auth*/
export interface PmAuthRequest {
  serialnum: number // pm的硬件地址 hw address 的值，请转换为10进制的数值
}
/*请求获取pm的auth的响应*/
export interface PmAuthResponse {
  serialnum: string // 认证的pm的hw address，同样是16进制字符串
  authenPw: string // 认证后的密码，返回的是16进制的字符串，客户端需要进行转换切割为8个16进制数，如：f1e117af15afda4c
}

/*登录请求信息*/
export interface LoginRequest {
  token: string // 用户token，必填
  uid: number // 用户编号，必填
  deviceId: string // 客户端识别标识，非必填，如：利用keyChain+UUID生成ios设备的唯一标识；IMEI，ANDROID_ID，浏览器指纹等信息
  platform: string // 客户端平台: Windows,Mac,Android,iOS,Web,CLIENT_WEB,Other，必填
  appVersion: string // APP版本号，非必填
  targetId: string //目标id，可以是赛事项目id，raceItemId
}

/*聊天请求消息*/
export interface MessageRequest {
  msg_id: number
  from: number // 消息发送用户编号
  to: number // 消息接收用户编号
  to_uids: string // 消息接收用户编号列表，中间以英文逗号间隔
  time: number
  msg_type: number
  content: string
  url: string
  property: string
  from_nick: string
  json: string
}

/*聊天响应消息，其他通用响应消息*/
export interface MessageResponse {
  success: boolean // true表示成功，false表示失败
  code: number // 错误码
  message: string // 错误描述
  result: string // 返回内容，json格式，额外内容
  expose: number // 错误描述是否提示给用户:1 提示;0 不提示
}

/*通知消息，socket节点之间的通信消息，不涉及客户端*/
export interface MessageNotification {
  no_id: number
  result: string // 消息内容，json格式
}

/*客户端 -> 服务端 的心跳请求消息*/
export interface MessageHeartBeat {
  uid: number // 用户编号，必填
  json: string // 心跳请求内容，可为空串
}

/*赛事项目参与人上线通知消息*/
export interface RaceUserOnlineNotification {
  curOnlineUser: UserInfo // 当前上线用户信息
  raceId: number // 关联的赛事id
  raceItemId: number // 关联的赛事项目id
  attendeeNum: number // 关联的赛事项目报名人数
  participationNum: number // 关联的赛事项目已参加人数
}
/*赛事项目参与人下线通知消息*/
export interface RaceUserOfflineNotification {
  offlineUser: UserInfo // 下线用户信息
  raceId: number // 关联的赛事id
  raceItemId: number // 关联的赛事项目id
  attendeeNum: number // 关联的赛事项目报名人数
  participationNum: number // 关联的赛事项目已参加人数
}

/*参赛用户信息*/
export interface UserInfo {
  uid: number // 用户编号，下线用户uid
  userName: string // 下线用户名
  avatar: string // 下线用户名的头像地址
  laneNum: string //
}

/*准备比赛命令消息*/
export interface PrepareRaceCmd {
  raceItemId: number
}
/*开始比赛命令消息*/
export interface StartRaceCmd {
  raceItemId: number
}
/*强制结束比赛命令消息*/
export interface StopRaceCmd {
  raceItemId: number
}

/*划船运动数据提交请求消息*/
export interface RowDataSubmitRequest {
  last: boolean // 分时记录时，标记是否是最后一条记录 true是，false否，默认是最后一条记录
  deviceId: string // pm设备识别信息
  phoneModel: string // 提交的手机型号
  targetType: number // 关联的目标类型：0.个人划船器记录 1.赛事划船记录 2.房间划船记录 3.pc竞赛
  targetId: string // 关联的目标id，如房间id, 赛事id，个人运动则传值0
  model: number // 模式: 0距离模式 1时间模式 2自由模式 3.挑战模式， 默认为0

  distance: number // 距离，单位米
  elapsedTimeSecs: number // 耗费时间，单位秒
  totalTimeUsed: number // 手机提交总用时,单位秒
  calories: number // 卡路里
  spm: number // 浆频（s/m）
  watts: number // 功率（瓦）
  pace: number // 配速
  maxSpeed: number // 最大速度

  avgCaloriesPerHour: number // 平均每小时卡路里消耗
  strokeCount: number // 桨数
  peakDriveForce: number // 峰力
  avgDriveForce: number // 均力
  splitOrIntervalSpeed: number // 划速
  heartRate: number // 心率
  dragFactor: number // 阻力系数

  strokeWattsArray: string // 功率list（瓦）
  strokeDriveLength: number // 功率list（瓦）
  strokeDriveLengthArray: string // 划距list（划一次从开始发力到结束，一共多长）
  strokeDriveLengthTime: number // 划距时间
  strokeDriveLengthTimeArray: string // 划距list（划一次从开始发力到结束，一共多长）
  strokeDriveTimeArray: string // 拉浆时间list（一浆的时间）
  strokeRecoveryTime: number // 回浆时间list（拉浆结束后，回浆的时间）
  strokeRecoveryTimeArray: string // 回浆时间list（拉浆结束后，回浆的时间）
  dragFactorArray: string // 阻力系数list
  heartRateArray: string // 心率list
  spmArray: string // 桨频list
  startTime: number // 开始划船时间，毫秒数
  endTime: number // 结束划船时间，毫秒数

  expand1: string // 预留字段1
  expand2: string // 预留字段2
  expand3: string // 预留字段3
}

/*实时运动数据响应消息 广播*/
export interface LiveRowDataResponse {
  uuid: string // 数据记录唯一id，客户端生成，用于判断是否是重复提交
  uid: number // 用户编号
  nickName: string // 用户昵称
  avatar: string // 用户头像url
  targetType: number // 关联的目标类型：0.个人划船器记录 1.赛事划船记录 2.房间划船记录 3.pc竞赛
  targetId: number // 关联的目标id，如赛事id 或 竞赛房间id
  startTime: number // 记录开始时间, 时间戳
  distance: number // 距离，单位米
  time: number // 时间，单位秒
  calories: number // 消耗的卡路里
  spm: number // 浆频（s/m）
  pace: number // 配速
}

/*排行榜item数据*/
export interface RankingItem {
  ranking: number // 排行名次
  uid: number // 用户编号
  nickName: string // 用户编号
  avatar: string // 用户编号
  laneNum: number // 泳道号
  distance: number // 里程，单位米
  time: number // 时间，单位秒
  timeStr: string
  paceStr: string
  calories: number // 消耗的卡路里
  spm: number // 浆频（s/m）
  pace: number // 配速
  location: string // 用户的所在地
}

/*排行榜数据List 广播*/
export interface RankingsResponse {
  ranking: RankingItem[]
}

/*准备或取消准备消息*/
export interface PrepareRequest {
  uid: number // 用户编号
  type: number // 类型：1准备消息 2取消准备
  target_type: number // 关联的目标类型：1.赛事划船准备类型 2.房间划船准备类型 3.pc竞赛
  target_id: number // 关联的目标id：赛事id 或 竞赛房间id
}

/*国际化消息，用于转发消息时转换使用*/
export interface I18nMessage {
  time_zone_offset: number // 客户端的时差信息，如东八区为8，数字类型，默认8，可为负数
  language: string // 客户端语言标识，zh:默认中文， en:英文
  message: string // 中文消息，server端转发使用，client不传递
  en_message: string // 英文消息，server端转发使用，client不传递
}

/*顶层消息 嵌套了各种类型消息*/
// 根据消息类型 type的值，来获取对应的消息类型
export interface Message {
  type: HeadType //通用字段: 消息类型，即上面消息枚举类的值，根据这个类型判断消息是什么类型消息
  sequence: number // 通用字段：消息序列号/应答流水，请求消息与应答消息相对应，若无对应的请求消息，则为0
  session_id: string // 通用字段：会话id
  i18nMessage: I18nMessage // 国际化消息，客户端语言环境和时区信息

  loginRequest: LoginRequest //登录请求
  loginResponse: MessageResponse //登录响应，返回

  messageRequest: MessageRequest // IM消息请求，预留
  messageResponse: MessageResponse // IM消息响应，预留

  notification: MessageNotification // 系统响应的通知消息，如：上线、下线消息

  heartBeat: MessageHeartBeat // 心跳消息

  rowDataSubmitRequest: RowDataSubmitRequest // 提交运动数据信息
  rowDataSubmitResponse: MessageResponse // 提交运动数据的响应消息，用于判断是否提交成功

  liveRowDataResponse: LiveRowDataResponse // 实时运动数据响应消息

  prepareRequest: PrepareRequest // 准备或取消准备的请求消息
  prepareResponse: MessageResponse // 准备或取消准备的响应消息

  // 赛事命令消息：准备比赛、开始比赛、强制结束比赛
  prepareRaceCmd: PrepareRaceCmd
  startRaceCmd: StartRaceCmd
  stopRaceCmd: StopRaceCmd

  rankingsResponse: RankingsResponse // 整个排行榜

  // 赛事用户上线/下线响应消息
  raceUserOnline: RaceUserOnlineNotification // 比赛用户上线消息
  raceUserOffline: RaceUserOfflineNotification // 比赛用户下线消息

  pmAuthRequest: PmAuthRequest // 请求获取pm的auth
  pmAuthResponse: PmAuthResponse // 请求获取pm的auth的响应
}
