import { genQueue, sendQueue } from './pmQueue'

/** 重置测力计编号
 */
const resetErg = (
  performanceMonitor: ergometer.PerformanceMonitorUsb,
  hwAddress: string,
  authen_pw: string
) => {
  return new Promise(function (resolve, reject) {
    const { queue, queuePush } = genQueue()
    queuePush('f0 ff 00  73 06 33 00  00 00 00 00')
    queuePush('f0 ff 00  7e 05 e1 0b  02 00 00')
    queuePush(`f0 fd 00  7e 07 10 05  ${hwAddress}  01`)
    queuePush(`f0 ff 00  7e 05 e1 0b  02 00 00`)
    queuePush(`f0 fd 00  7e 07 10 05  ${hwAddress}  01`)
    queuePush('f0 ff 00  7e 05 e1 0b  02 00 00')
    queuePush(`f0 fd 00  7e 07 10 05  ${hwAddress}  01`)
    // 获取hw地址
    queuePush('f0 01 00  7e 01 82')
    queuePush(`f0 01 00  7e 06 50 04  ${hwAddress}`)
    queuePush('f0 01 00  7e 01 80')
    queuePush('f0 01 00  7f 01 9a')
    // auth加密
    queuePush(`f0 01 00  7e 0e 1a 0c  ${hwAddress}  ${authen_pw}`)
    sendQueue(performanceMonitor, queue).then(() => {
      console.log('resterg success')
      //@ts-ignore
      resolve()
    })
  })
}

export default resetErg
