import { useStorage } from '@/hooks';
import { useSubmit } from '@/hooks/socket';
import { ERunStatus, IRoomType, STORAGE_KEYS, TRunStatus } from '@/types';
import { RowDataSubmitRequest } from '@/types/proto';
import getPm5Data from '@/utils/getPm5Data';
import { useUnmount } from 'ahooks';
import { useEffect, useState } from 'react';
import useParams from './useParams';

/** 从划船机获取数据 */
const usePm5Data = (distanceTotal: number, runStatus: TRunStatus, roomType: IRoomType) => {
  const targetId = useParams('id')
  const [serialnum] = useStorage(STORAGE_KEYS.serialnum)
  const [raceData, setRaceData] = useState<Partial<RowDataSubmitRequest>>({
    distance: 0, elapsedTimeSecs: 0, calories: 0, pace: 0,
  })
  const [startTime, setStartTime] = useState(0)
  const [sendSubmitSocket] = useSubmit({ roomType })

  const reset = () => {
    getPm5Data().reset()
  }

  useEffect(() => {
    //停止比赛则不再获取数据
    if (runStatus === ERunStatus.END) {
      reset()
    }
  }, [runStatus])

  useUnmount(() => {
    reset()
  });

  useEffect(() => {
    let sendStartTime = 0
    if (!startTime) {
      sendStartTime = new Date().getTime()
      setStartTime(sendStartTime)
    }
    // 比赛中才需要提交比赛数据
    if (runStatus === ERunStatus.RACING && (roomType === 'racing' || roomType === 'prepare')) {
      const { distance, elapsedTimeSecs, calories, pace, spm } = raceData
      if (!distance) { //划船距离为0就不提交
        return
      }
      sendSubmitSocket({
        distance, startTime: startTime || sendStartTime,
        elapsedTimeSecs,
        targetId,
        calories,
        targetType: 3,
        deviceId: `pm5 ${serialnum}`,
        pace,
        spm
      })
    }
  }, [raceData.distance, runStatus, roomType])


  useEffect(() => {
    if (raceData.distance >= distanceTotal || runStatus !== ERunStatus.RACING) {
      getPm5Data().reset()
    }

    if (runStatus === ERunStatus.RACING && roomType !== 'watch') {
      getPm5Data().start(distanceTotal, setRaceData)
    }
  }, [raceData.distance, runStatus, roomType])


  return { distance: raceData.distance, reset, pace: raceData.pace, time: raceData.elapsedTimeSecs, spm: raceData.spm }
}

export default usePm5Data