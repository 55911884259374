import Spin from './Spin';

const SetRaceLoading = () => {
  return (
    <div style={{ position: 'fixed', display: 'flex', width: '100%', height: '100%', zIndex: 10, backgroundColor: 'rgba(0,0,0,.5)' }}>

      <Spin size='large' style={{ margin: 'auto' }} tip='正在设置比赛，请稍等...' />
    </div>
  );
};

export default SetRaceLoading
