const getCheckSum = (arr: string[]) => {
  let checksum = 0
  //start from the third byte, because the first and second bytes are address info
  for (let i = 3; i < arr.length; i++)
    //@ts-ignore
    checksum = checksum ^ arr[i]

  return checksum.toString(16)
}

export default getCheckSum
