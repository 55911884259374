import { Button } from '@/components';
import { IRaceItem, IRoomType, TRunStatus } from '@/types';
import getDuration from '@/utils/getDuration';
import { useCountDown } from 'ahooks';
import dayjs from 'dayjs';
import { FC } from 'react';
import logo from '../../../assets/logoWhite.png';
import '../index.less';

interface IProps {
  roomType: IRoomType;
  runStatus: TRunStatus;
  data: IRaceItem;
  startCountDown: () => void;
  exit: () => void
}

const Top: FC<IProps> = ({ roomType, data, startCountDown, runStatus, exit }) => {
  const now = new Date().getTime()
  /** 开始时间倒计时 */
  const [countStartTime] = useCountDown({
    leftTime: data?.countDownSeconds * 1000
  })

  /** 结束时间倒计时 */
  const [countEndTime] = useCountDown({
    leftTime: data?.endCountDownSeconds * 1000
  })

  /** 比赛已经开始，可以进入比赛的倒计时 */
  const [countJoinTime] = useCountDown({
    leftTime: data?.startTimeStamp + 10 * 60 * 1000 - dayjs().valueOf()
  })

  const renderCountStartTime = () => {
    return <span className='time'>{getDuration(countStartTime)}</span>
  }

  const renderCountEndTime = () => {
    return <span className='time'>{getDuration(countEndTime)}</span>
  }

  const renderTest = () => {
    return <>
      {
        data?.countDownSeconds > 0
          ? <span className='count-down test prepare'>
            练习模式
            {renderCountStartTime()}
            后比赛开始
          </span>
          : <span className='count-down test continue'>
            比赛已开始，请在{getDuration(countJoinTime)}进入比赛
          </span>
      }
      <Button
        // disabled={runStatus === ERunStatus.RACING}
        color='yellow' className='start' onClick={() => startCountDown()}>
        开始练习
      </Button>
    </>
  }

  const renderRace = () => {
    return <>
      {
        countStartTime
          ? <span className='count-down racing prepare'>
            {renderCountStartTime()}
            后开始
          </span>
          : (
            countEndTime
              ? <span className='count-down racing continue'>
                {renderCountEndTime()}
                后结束
              </span>
              : <span className='count-down racing end'>
                赛事已结束
              </span>
          )
      }
    </>
  }

  return (
    <div className='top'>
      <div className='left'>
        <img src={logo} />
        <span className='title'>{data?.raceName}/{data?.itemName}</span>
      </div>
      <div className='right'>
        {
          roomType === 'test'
            ? renderTest()
            : renderRace()
        }
        <Button color='blue' className='exit' onClick={() => exit()}>退出{roomType === 'test' ? '练习' : '比赛'}</Button>
      </div>
    </div>
  );
};

export default Top;
