import {
  PM_LONG_PUSH_CFG_CMDS,
  PM_SHORT_PULL_DATA_CMDS,
} from '@/types/csafe.defs'
import toHexStringArr from '../toHexStringArr'
/**
 * 准备比赛
 */
const prepareRace = (
  performanceMonitor: ergometer.PerformanceMonitorUsb,
  {
    limitType,
    limitValue,
  }: {
    /**
     * 限制类型，1限制里程M, 2限制时间秒内最大距离，3限制时间秒内最大卡路里，4限制卡路里
     */
    limitType: number
    /**
     * 限制类型的值，项目的目标值
     */
    limitValue: number
  }
) => {
  console.log('prepareRace')
  performanceMonitor
    .newCsafeBuffer()
    //@ts-ignore 自己封装的指令
    .setPmCfg({
      waitForResponse: true,
      address: [0x01, 0x00], // [destination, source]
      data: [PM_SHORT_PULL_DATA_CMDS.PM_GET_SYNCDATA],
    })
    .sendExtended()
  performanceMonitor
    .newCsafeBuffer()
    //@ts-ignore 自己封装的指令
    .setPmCfg({
      waitForResponse: true,
      address: [0x01, 0x00], // [destination, source]
      data: [PM_LONG_PUSH_CFG_CMDS.PM_SET_RACEOPERATIONTYPE, 0x01, 0x06],
    })
    .sendExtended()

  const arr = toHexStringArr(limitType === 1 ? limitValue : limitValue * 100) //时间要乘以100
  //时间赛
  // 1d 04 00 00 00 80
  // 1f 04 00 00 02 00
  // 20 04  00 00 0a 00
  // 01 01 05
  // 03  05 00 00 00 17 70
  // 05 05  00 00 00 0b
  // b8 09 01 01
  // 距离赛
  // 0x1d,0x04,0x00, 0x00,0x00,0x80,
  // 0x1f,0x04,0x00, 0x00, 0x02,0x00,
  // 0x20,0x04,0x00, 0x00, 0x0a,0x00,
  // 0x01, 0x01,0x03,
  // 0x03,0x05,0x80,0x00,0x00,arr[0],arr[1],
  // 0x05,0x05,0x80,0x00,0x00,0x01,0xf4,
  // 0x09,0x01,0x00,
  performanceMonitor
    .newCsafeBuffer()
    //@ts-ignore 自己封装的指令
    .setPmCfg({
      waitForResponse: true,
      address: [0x01, 0x00], // [destination, source]
      // 07 d0是2000m, 01 f4是500m, 00 64是100m
      data: [
        0x1d,
        0x04,
        0x00,
        0x00,
        0x00,
        0x80,
        0x1f,
        0x04,
        0x00,
        0x00,
        0x02,
        0x00,
        0x20,
        0x04,
        0x00,
        0x00,
        0x0a,
        0x00,
        0x01,
        0x01,
        limitType === 1 ? 0x03 : 0x05,
        0x03,
        0x05,
        limitType === 1 ? 0x80 : 0x00,
        0x00,
        0x00,
        arr[0],
        arr[1],
        0x05,
        0x05,
        limitType === 1 ? 0x80 : 0x00,
        0x00,
        0x00,
        0x01,
        0xf4,
        0x09,
        0x01,
        limitType === 1 ? 0x00 : 0x01,
      ],
    })
    .sendExtended()
  performanceMonitor
    .newCsafeBuffer()
    //@ts-ignore 自己封装的指令
    .setPmCfg({
      waitForResponse: true,
      address: [0x01, 0x00], // [destination, source]
      data: [0x14, 0x01, 0x01],
    })
    .sendExtended()
  // 关键指令，pm停止，显示准备开始
  performanceMonitor
    .newCsafeBuffer()
    //@ts-ignore 自己封装的指令
    .setPmCfg({
      waitForResponse: true,
      address: [0x01, 0x00], // [destination, source]
      data: [PM_LONG_PUSH_CFG_CMDS.PM_SET_SCREENSTATE, 0x02, 0x02, 0x04],
    })
    .sendExtended()
  performanceMonitor
    .newCsafeBuffer()
    //@ts-ignore 自己封装的指令
    .setPmCfg({
      waitForResponse: true,
      address: [0x01, 0x00], // [destination, source]
      data: [PM_LONG_PUSH_CFG_CMDS.PM_SET_RACEOPERATIONTYPE, 0x01, 0x08],
    })
    .sendExtended()
}
export default prepareRace
