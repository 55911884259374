import type { FC, PropsWithChildren } from 'react';
import { ErrorBoundary as MyErrorBoundary } from 'react-error-boundary';

const FallbackComponent = ({ error }: { error: Error }) => {
  return (
    <div style={{ display: 'flex', width: '100%', height: '100%' }}>
      <div
        style={{
          display: 'flex',
          textAlign: 'center',
          flexDirection: 'column',
          margin: 'auto',
        }}
      >
        <h1>
          貌似出错啦！
        </h1>
        <pre>出错原因：{error?.message}</pre>
        <pre>详细信息：{error?.stack}</pre>
      </div>
    </div>
  );
};

const ErrorBoundary: FC<PropsWithChildren<{}>> = (props) => (
  // @ts-ignore
  <MyErrorBoundary FallbackComponent={FallbackComponent}>{props.children}</MyErrorBoundary>
);

export default ErrorBoundary;
