import { HeadType, Message } from '@/types/proto';
import emitter from '@/utils/emitter';
import { useState } from 'react';

/** 比赛结束通知消息 —— RaceUserOnlineNotification */
const useStop = () => {
  const [raceItemId, setRaceItemId] = useState(null)
  emitter.on('onMessage', (message: Message) => {
    if (message?.type === HeadType.STOP_RACE_CMD) {
      console.log('on stop', message)
      setRaceItemId(message?.stopRaceCmd.raceItemId)
    }
  });

  return raceItemId
}

export default useStop