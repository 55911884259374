import { IRaceItem } from '@/types'
import { RankingItem } from '@/types/proto'
import { Get } from '@/utils/request'

/** 获取赛事项目详情 */
export const getRaceItemAPI = async (raceItemId: string) => {
  return await Get<IRaceItem>(`/attend_race/${raceItemId}`)
}

/** 查询赛事项目的排行 */
export const getRankingAPI = async (raceItemId: string | number) => {
  return await Get<RankingItem[]>(`/ranking/${raceItemId}`)
}
