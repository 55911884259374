import { PM_LONG_PUSH_CFG_CMDS } from '@/types/csafe.defs'
/**
     * 退出比赛模式，回到主界面
     f0 01 00 76 03 1e 01 06 6c f2
     f0 ff 00 76 04 13 02 02 06 67 f2 比赛空闲状态，界面出现等待下一步指示，该命令无返回值
     f0 ff 00 76 03 1e 01 06 6c f2 该命令无返回值
     f0 ff 00 76 04 13 02 02 15 74 f2 回到主界面，该命令无返回值
     */
const quitRaceToMain = (
  performanceMonitor: ergometer.PerformanceMonitorUsb
) => {
  performanceMonitor
    .newCsafeBuffer()
    //@ts-ignore 自己封装的指令
    .setPmCfg({
      address: [0x01, 0x00],
      data: [PM_LONG_PUSH_CFG_CMDS.PM_SET_SCREENSTATE, 0x02, 0x02, 0x15],
      onDataReceived: function (response: any) {
        console.log('回到主页面的第一个命令返回：', response)
        if (response && response.length > 0) {
          var responseArr = []
          for (var i = 0; i < response.length; i++) {
            responseArr.push(response[i].toString(16))
          }
          console.log('回到主页面的第一个命令放回：', responseArr)
        }

        // 执行广播命令：
        // f0 ff 00 76 04 13 02 02 06 67 f2
        // f0 ff 00 76 03 1e 01 06 6c f2
        // f0 ff 00 76 04 13 02 02 15 74 f2
        performanceMonitor
          .newCsafeBuffer()
          //@ts-ignore 自己封装的指令
          .setPmCfg({
            waitForResponse: false,
            address: [0xff, 0x00], // [destination, source]
            data: [PM_LONG_PUSH_CFG_CMDS.PM_SET_SCREENSTATE, 0x02, 0x02, 0x06],
          })
          .sendExtended()
        performanceMonitor
          .newCsafeBuffer()
          //@ts-ignore 自己封装的指令
          .setPmCfg({
            waitForResponse: false,
            address: [0xff, 0x00], // [destination, source]
            data: [PM_LONG_PUSH_CFG_CMDS.PM_SET_RACEOPERATIONTYPE, 0x01, 0x06],
          })
          .sendExtended()
        performanceMonitor
          .newCsafeBuffer()
          //@ts-ignore 自己封装的指令
          .setPmCfg({
            waitForResponse: false,
            address: [0xff, 0x00], // [destination, source]
            data: [PM_LONG_PUSH_CFG_CMDS.PM_SET_SCREENSTATE, 0x02, 0x02, 0x15],
          })
          .sendExtended()
      },
    })
    .sendExtended()
}

export default quitRaceToMain
