import { HeadType, Message } from '@/types/proto';
import emitter from '@/utils/emitter';
import { useState } from 'react';

/** 比赛开始通知消息 */
const useStart = () => {
  const [raceItemId, setRaceItemId] = useState(null)
  emitter.on('onMessage', (message: Message) => {
    if (message?.type === HeadType.START_RACE_CMD) {
      console.log('on start', message)
      setRaceItemId(message?.startRaceCmd.raceItemId)
    }
  });

  return raceItemId
}

export default useStart