import { IRoomType } from '@/types';
import { HeadType, Message, RankingItem } from '@/types/proto';
import emitter from '@/utils/emitter';
import { getRankingAPI } from '@/views/Race/Room/webapi';
import { useMount } from 'ahooks';
import { message } from 'antd';
import { useState } from 'react';

/** 排名通知消息 —— RaceUserOnlineNotification */
/** 如果是已经结束的观赛，则从rest接口获取，否则建立socket */
const useRank = (raceItemId: string, roomType: IRoomType) => {
  // const fakeData = [{
  //   ranking: 1,
  //   uid: 1,
  //   nickName: '1',
  //   distance: 0,
  //   time: 0,
  // }, {
  //   ranking: 2,
  //   uid: 2,
  //   nickName: '2',
  //   distance: 0,
  //   time: 0,
  // }, {
  //   ranking: 3,
  //   uid: 3,
  //   nickName: '3',
  //   distance: 0,
  //   time: 0,
  // }]
  // 先造点假数据
  const [rankList, setRankList] = useState<Partial<RankingItem>[]>([])

  // 先造点假数据
  // useTimeout(() => {
  //   setRankList([{
  //     ranking: 1,
  //     uid: 1,
  //     nickName: '1',
  //     distance: 100,
  //     time: 0,
  //   }, {
  //     ranking: 2,
  //     uid: 2,
  //     nickName: '2',
  //     distance: 120,
  //     time: 0,
  //   }, {
  //     ranking: 3,
  //     uid: 3,
  //     nickName: '3',
  //     distance: 250,
  //     time: 0,
  //   }])
  // }, 2000)

  // useTimeout(() => {
  //   setRankList([{
  //     ranking: 1,
  //     uid: 1,
  //     nickName: '1',
  //     distance: 200,
  //     time: 0,
  //   }, {
  //     ranking: 2,
  //     uid: 2,
  //     nickName: '2',
  //     distance: 220,
  //     time: 0,
  //   }, {
  //     ranking: 3,
  //     uid: 3,
  //     nickName: '3',
  //     distance: 350,
  //     time: 0,
  //   }])
  // }, 3000)

  // useTimeout(() => {
  //   setRankList([{
  //     ranking: 3,
  //     uid: 1,
  //     nickName: '1',
  //     distance: 200,
  //     time: 0,
  //   }, {
  //     ranking: 2,
  //     uid: 2,
  //     nickName: '2',
  //     distance: 220,
  //     time: 0,
  //   }, {
  //     ranking: 1,
  //     uid: 3,
  //     nickName: '3',
  //     distance: 350,
  //     time: 0,
  //   }])
  // }, 4000)

  // useTimeout(() => {
  //   setRankList([{
  //     ranking: 3,
  //     uid: 1,
  //     nickName: '1',
  //     distance: 250,
  //     time: 0,
  //   }, {
  //     ranking: 2,
  //     uid: 2,
  //     nickName: '2',
  //     distance: 270,
  //     time: 0,
  //   }, {
  //     ranking: 1,
  //     uid: 3,
  //     nickName: '3',
  //     distance: 400,
  //     time: 0,
  //   }])
  // }, 5000)

  // useTimeout(() => {
  //   setRankList([{
  //     ranking: 3,
  //     uid: 1,
  //     nickName: '1',
  //     distance: 300,
  //     time: 0,
  //   }, {
  //     ranking: 1,
  //     uid: 2,
  //     nickName: '2',
  //     distance: 1000,
  //     time: 0,
  //   }, {
  //     ranking: 2,
  //     uid: 3,
  //     nickName: '3',
  //     distance: 450,
  //     time: 0,
  //   }])
  // }, 6000)

  useMount(async () => {
    // 观赛模式，已结束的比赛，才从rest接口获取
    if (roomType === 'watch') {
      const { res, err } = await getRankingAPI(raceItemId)
      if (err) {
        message.error(err?.msg)
        return
      }
      setRankList(res)
    }
  })



  emitter.on('onMessage', (message: Message) => {
    if (message?.type === HeadType.RANKINGS_RESPONSE) {
      console.log('on raceUserRank', message)
      setRankList(message?.rankingsResponse.ranking.map((ranking) => {
        const { distance, ...rest } = ranking
        return {
          distance: Number(distance.toFixed(1)),
          ...rest
        }
      }))
    }
  });

  return rankList
}

export default useRank