import { IRaceItem } from '@/types'
import { getRaceListAPI } from '@/views/Race/List/webapi'
import { useMount } from 'ahooks'
import { message } from 'antd'
import { useState } from 'react'

// 获取本地存储的race列表数据，并且会监听socket动态修改
const useRaceListData = (): IRaceItem[] => {
  const [attendRaceItems, setAttendRaceItems] = useState([])

  useMount(async () => {
    const { res, err } = await getRaceListAPI()
    if (err) {
      message.error(err?.msg)
      return
    }
    setAttendRaceItems(res)
  })

  return attendRaceItems
}

export default useRaceListData
