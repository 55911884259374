import { message } from 'antd'
import autoAssign from './autoAssign'
import connect from './connect'
import getHwAdress from './getHwAdress'
import getRaceData from './getRaceData'
import onConnected from './onConnected'
import prepareRace from './prepareRace'
import quitRaceToMain from './quitRaceToMain'
import resetErg from './restErg'
import setRace from './setRace'
import startRace from './startRace'
import stop from './stop'

class Monitor {
  private performanceMonitor
  constructor() {
    this.performanceMonitor = new ergometer.PerformanceMonitorUsb()
  }

  /** 连接设备 */
  connect() {
    return connect(this.performanceMonitor)
  }

  /** 连接成功 */
  onConnected(onSuccess: () => void) {
    onConnected(() => {
      onSuccess()
    }, this.performanceMonitor)
  }

  // 获取pm设备的hw adress
  getHwAdress() {
    return getHwAdress(this.performanceMonitor)
  }

  // 强制结束
  stop() {
    return stop(this.performanceMonitor)
  }

  // 获取运动数据
  getRaceData() {
    return getRaceData(this.performanceMonitor)
  }

  /**
     * 退出比赛模式，回到主界面
     f0 01 00 76 03 1e 01 06 6c f2
     f0 ff 00 76 04 13 02 02 06 67 f2 比赛空闲状态，界面出现等待下一步指示，该命令无返回值
     f0 ff 00 76 03 1e 01 06 6c f2 该命令无返回值
     f0 ff 00 76 04 13 02 02 15 74 f2 回到主界面，该命令无返回值
     */
  quitRaceToMain() {
    return quitRaceToMain(this.performanceMonitor)
  }

  /**
   * 开始比赛
   */
  startRace() {
    return new Promise((resolve) => {
      startRace(this.performanceMonitor)
      resolve(null)
    })
  }

  /** 自动分配测力计编号
   */
  autoAssign() {
    return autoAssign(this.performanceMonitor)
  }

  resetErg(hwAddress: string, authen_pw: string) {
    return resetErg(this.performanceMonitor, hwAddress, authen_pw)
  }

  /** 是否处在连接中 */
  isConnected() {
    const device = this.performanceMonitor.device
    if (device) {
      return true
    } else {
      message.error('还未连接划船机')
      return false
    }
  }

  /**
   * 设置比赛 并进入热身状态
   */
  setRace(pmDisplayRacer: string, pmDisplayName: string) {
    if (this.isConnected()) {
      return new Promise((resolve) => {
        setRace(this.performanceMonitor, pmDisplayRacer, pmDisplayName)
        // 暂时手动设置6秒后准备成功
        setTimeout(() => {
          resolve(null)
        }, 1000 * 6)
      })
    }
  }

  /**
   * 准备比赛
   */
  prepareRace({
    limitType,
    limitValue,
  }: {
    limitType: number
    limitValue: number
  }) {
    return new Promise((resolve) => {
      prepareRace(this.performanceMonitor, {
        limitType,
        limitValue,
      })
      // 暂时手动设置8秒后准备成功
      setTimeout(() => {
        resolve(null)
      }, 1000 * 8)
    })
  }
}

export default new Monitor()
