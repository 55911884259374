
import { useCountDown, useMount } from 'ahooks';
import { Progress } from 'antd';
import { FC, useState } from 'react';
import './index.less';

interface IProps {
  type?: 'test' | 'racing'
  onClose?: () => void
}

const CountDown: FC<IProps> = ({ type = 'test', onClose }) => {
  const total = 3000 // 倒计时3秒
  const [targetDate, setTargetDate] = useState<number>();

  const [countdown] = useCountDown({
    targetDate,
    interval: 50,
    onEnd: () => {
      onClose()
    },
  });

  useMount(() => {
    setTargetDate(Date.now() + total);
  })

  return (
    <div className='count-down-box'>
      <Progress width={285}
        type="circle"
        strokeColor='white'
        trailColor='#08102E'
        percent={(total - countdown) / total * 100}
        format={() => <div className='text'>
          <div className='number'>{Math.round(countdown / 1000)}</div>
          <div className='unit'>秒</div>
          <div className='tip'>后开始{type === 'test' ? '练习' : '比赛'}</div>
        </div>} />
    </div>
  );
};

export default CountDown
