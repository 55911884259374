import { load, Root } from 'protobufjs'

const getProtoRoot = async (): Promise<Root> => {
  return new Promise((resolve) => {
    load('proto.json', function (err, root) {
      if (err) throw err
      resolve(root)
    })
  })
}

const getProtoMessage = async () => {
  const protoRoot = await getProtoRoot()
  const packageName = 'cn.lzc.row.im.proto'
  const ProtoMessage = protoRoot.lookupType(`${packageName}.Message`)
  return ProtoMessage
}

export default getProtoMessage
