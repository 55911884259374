import wave1 from '@/assets/wave1.png';
import wave2 from '@/assets/wave2.png';
import { useParams } from '@/hooks';
import { IRoomType } from '@/types';
import { RankingItem } from '@/types/proto';
import { FC } from 'react';
import './rowing.less';

interface IProps {
  /** 在第几列 */
  columnIndex: number;
  /** 一共有几列 */
  columnCount: number;
  /** 在第几行 */
  row: number;
  mode: 'light' | 'dark';
  distanceTotal: number;
  rankItem?: Partial<RankingItem>
  firstUserDistance: number // 第一名跑了多远
}

// 未到达终点的
const Rowing: FC<IProps> = ({ mode, rankItem, distanceTotal, columnCount, row, columnIndex, firstUserDistance }) => {
  const roomType = useParams('type') as IRoomType
  const firstLeftPercent = (firstUserDistance || 0) / distanceTotal // 第一名跑了距离百分比
  const range = Math.ceil(firstUserDistance - rankItem?.distance) // 第一名和当前的差距多少米
  const rangePercent = columnCount === 1 ? range / 200 : range / 100 //当前差距在页面上占比，一列的话整个页面是200m，其余的是100m
  const leftPercent = (firstLeftPercent > rangePercent) ? (firstLeftPercent - rangePercent) : 0

  const renderDistance = () => {
    if ((rankItem?.ranking === 1 && roomType !== 'test') || rankItem?.distance === 0) {
      return null
    }
    if (rankItem?.ranking === 1 && roomType === 'test') {
      return <span className='distance'>{rankItem?.distance}m</span>
    }
    return <span className='distance'>{range}m</span>
  }

  return (
    <div className={`run-way ${mode} rowing ${columnIndex}-${row}`} >
      {
        <img src={mode === 'light' ? wave1 : wave2} className='wave-img' />
      }
      {
        rankItem
          ? <>
            <div className='boat-rowing' style={{
              "left": `calc((100% - 200px) * ${leftPercent})`
            }}>
              <span className='rank'>{rankItem?.laneNum}</span>
              {
                renderDistance()
              }
            </div>
            <div className='nick-name'>{rankItem?.nickName}</div>
            {
              rankItem?.distance ? <div className='right'>
                <span className='pace'>{rankItem?.spm}</span>
                <span className='time'>{rankItem?.paceStr}</span>
              </div> : null
            }
          </>
          : null
      }
    </div>
  );
};

export default Rowing;
