import { PM_LONG_PUSH_CFG_CMDS } from '@/types/csafe.defs'
import hexSetRace from '../hexSetRace'

/**
 * 设置比赛 并进入热身状态
 */
const setRace = (
  performanceMonitor: ergometer.PerformanceMonitorUsb,
  pmDisplayRacer: string,
  pmDisplayName: string
) => {
  console.log('setRace')
  performanceMonitor
    .newCsafeBuffer()
    //@ts-ignore 自己封装的指令
    .setPmCfg({
      waitForResponse: true,
      address: [0x01, 0x00], // [destination, source]
      data: [PM_LONG_PUSH_CFG_CMDS.PM_SET_SCREENSTATE, 0x02, 0x02, 0x08],
    })
    .sendExtended()
  performanceMonitor
    .newCsafeBuffer()
    //@ts-ignore 自己封装的指令
    .setPmCfg({
      waitForResponse: true,
      address: [0x01, 0x00], // [destination, source]
      data: [PM_LONG_PUSH_CFG_CMDS.PM_SET_RACEOPERATIONTYPE, 0x01, 0x06],
    })
    .sendExtended()

  // 设置比赛名称
  performanceMonitor
    .newCsafeBuffer()
    //@ts-ignore 自己封装的指令
    .setPmData({
      waitForResponse: false,
      address: [0xff, 0x00], // [destination, source]
      data: hexSetRace(pmDisplayName, 'raceName'),
    })
    .sendExtended()

  // 设置参赛者名称: Lane_2，泳道号为1
  // Lane_2 的的16进制为： 0x4c 0x61 0x6e 0x65 0x5f 032
  performanceMonitor
    .newCsafeBuffer()
    //@ts-ignore 自己封装的指令
    .setPmData({
      waitForResponse: false,
      address: [0xff, 0x00], // [destination, source]
      data: hexSetRace(pmDisplayRacer, 'racerName'),
    })
    .sendExtended()

  performanceMonitor
    .newCsafeBuffer()
    //@ts-ignore 自己封装的指令
    .getPmCfg({
      waitForResponse: true,
      address: [0x01, 0x00], // [destination, source]
      data: [0x96],
    })
    .sendExtended()
  performanceMonitor
    .newCsafeBuffer()
    //@ts-ignore 自己封装的指令
    .setPmCfg({
      waitForResponse: true,
      address: [0x01, 0x00], // [destination, source]
      data: [PM_LONG_PUSH_CFG_CMDS.PM_SET_SCREENSTATE, 0x02, 0x02, 0x27],
    })
    .sendExtended()
  performanceMonitor
    .newCsafeBuffer()
    //@ts-ignore 自己封装的指令
    .setPmCfg({
      waitForResponse: true,
      address: [0x01, 0x00], // [destination, source]
      data: [PM_LONG_PUSH_CFG_CMDS.PM_SET_SCREENSTATE, 0x02, 0x01, 0x1f],
    })
    .sendExtended()
  performanceMonitor
    .newCsafeBuffer()
    //@ts-ignore 自己封装的指令
    .setPmCfg({
      waitForResponse: true,
      address: [0x01, 0x00], // [destination, source]
      data: [0x01, 0x01, 0x00],
    })
    .sendExtended()
  // 进入热身状态
  performanceMonitor
    .newCsafeBuffer()
    //@ts-ignore 自己封装的指令
    .setPmCfg({
      waitForResponse: true,
      address: [0x01, 0x00], // [destination, source]
      data: [PM_LONG_PUSH_CFG_CMDS.PM_SET_SCREENSTATE, 0x02, 0x02, 0x03],
    })
    .sendExtended()
  performanceMonitor
    .newCsafeBuffer()
    //@ts-ignore 自己封装的指令
    .setPmCfg({
      waitForResponse: true,
      address: [0x01, 0x00], // [destination, source]
      data: [PM_LONG_PUSH_CFG_CMDS.PM_SET_RACEOPERATIONTYPE, 0x01, 0x0c],
    })
    .sendExtended()
}

export default setRace
