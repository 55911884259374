import { Confirm, CountDown, Dialog } from '@/components';
import { IRaceItem, IRoomType, TRunStatus } from '@/types';
import monitor from '@/utils/monitor';
import { useAsyncEffect } from 'ahooks';
import { FC } from 'react';
import { useNavigate } from 'react-router';
import Bottom from './components/Bottom';
import Top from './components/Top';
import './index.less';

interface IProps {
  roomType: IRoomType;
  runStatus: TRunStatus;
  data: IRaceItem;
  onStartRace: () => void;
}

const Header: FC<IProps> = ({ roomType, data, onStartRace, runStatus }) => {
  const navigate = useNavigate()

  const startCountDown = async () => {
    if (monitor.isConnected()) {
      await Dialog.load(<CountDown />, {
        width: 285,
        closable: false,
        className: 'count-down',
        maskClosable: false
      });
      monitor.startRace()

      onStartRace()
    }
  }

  useAsyncEffect(async () => {
    // 进入正在比赛的房间就直接开始比赛
    if (roomType === 'racing' && runStatus === 'ready') {
      startCountDown()
    }

    // 进入房间准备好，收到比赛命令就开始比赛
    if (roomType === 'prepare' && runStatus === 'racing') {
      startCountDown()
    }

    // 停止比赛pm则展示空闲
    if (runStatus === 'end') {
      monitor.stop()
    }
  }, [roomType, runStatus])

  const exit = () => {
    Confirm({
      title: `是否退出${roomType === 'test' ? '练习' : '比赛'}?`,
      content: `确定退出当前${roomType === 'test' ? '练习' : '比赛'}?`,
      onOk: () => {
        if (roomType !== 'watch') {
          monitor.stop()
        }
        navigate('/race/list')
      }
    })
  }

  return (
    <div className='race-header'>
      <Top
        roomType={roomType}
        data={data}
        startCountDown={startCountDown}
        runStatus={runStatus}
        exit={exit} />
      <Bottom data={data} />
    </div>
  );
};

export default Header;
