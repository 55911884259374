import { PM_LONG_PUSH_CFG_CMDS } from '@/types/csafe.defs'
// 强制结束
const stop = (performanceMonitor: ergometer.PerformanceMonitorUsb) => {
  // pm展示比赛空闲，等待下一个指令，关键指令
  performanceMonitor
    .newCsafeBuffer()
    //@ts-ignore 自己封装的指令
    .setPmCfg({
      waitForResponse: true,
      address: [0x01, 0x00], // [destination, source]
      data: [PM_LONG_PUSH_CFG_CMDS.PM_SET_SCREENSTATE, 0x02, 0x02, 0x06],
    })
    .sendExtended()
}

export default stop
