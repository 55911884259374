import React from 'react';

const routes = [
  {
    path: '/',
    element: React.lazy(() => import('./views/Login'))
  },
  {
    path: '/race/list',
    element: React.lazy(() => import('./views/Race/List'))
  },
  {
    path: '/race/room',
    element: React.lazy(() => import('./views/Race/Room'))
  }
];

export default routes
