import { message } from 'antd'
import axios, { AxiosError, AxiosRequestConfig } from 'axios'

const TIMEOUT = 10 // 超时时间,单位 s

interface IError {
  msg: string
}

export type ErrorWrap<T> = { res: T; err?: IError }

export interface IResponse<T> {
  code?: number
  msg?: string
  data?: T
}

const instance = axios.create({
  timeout: TIMEOUT * 1000,
  // validateStatus: (status) => status < 500, // 默认是 [200,300)
})

/** 设置默认的host */
const setDefaultHost = (host: string) => (instance.defaults.baseURL = host)

/** 设置公共 header */
const setCommonHeader = (headers: any) => {
  Object.keys(headers).forEach((key) => {
    instance.defaults.headers.common[key] = headers[key]
  })
}

/** 错误处理 */
instance.interceptors.response.use(
  (response) => {
    if (response?.data?.code > 200) {
      return Promise.reject(response?.data)
    }
    return response
  },
  // status >= 500
  (error: AxiosError) => {
    // 断网
    if (!window.navigator.onLine) {
      message.error('网络异常，请检查网络是否正常连接')
    }

    return Promise.reject(error.response?.data)
  }
)

/**
 * 封装业务request
 */
async function request<T>(
  url: string,
  config: AxiosRequestConfig
): Promise<ErrorWrap<T>> {
  const { method = 'POST', headers, data: requestData, ...restConfig } = config

  const withCredentials =
    config.withCredentials || instance.defaults.withCredentials

  try {
    const params = {
      url,
      method,
      headers,
      [method === 'GET' ? 'params' : 'data']: requestData,
      ...restConfig,
      withCredentials:
        withCredentials ?? !instance.defaults.headers.common['Authorization'],
    }

    const { data } = await instance.request<IResponse<T>>(params)
    return { res: data.data } as ErrorWrap<T>
  } catch (err: any) {
    return { err, msg: err?.message, res: null } as ErrorWrap<T>
  }
}

function Post<T>(uri: string, data?: object, config?: AxiosRequestConfig) {
  return request<T>(uri, { method: 'POST', data, ...config })
}

function Get<T>(uri: string, data?: object, config?: AxiosRequestConfig) {
  return request<T>(uri, { method: 'GET', data, ...config })
}

function Put<T>(uri: string, data?: object, config?: AxiosRequestConfig) {
  return request<T>(uri, { method: 'PUT', data, ...config })
}

function Delete<T>(uri: string, data?: object, config?: AxiosRequestConfig) {
  return request<T>(uri, { method: 'DELETE', data, ...config })
}

/** 注册http状态码对应的handler */
const registerHander = (status: number, handler: (data?: any) => void) => {
  if (!status) return console.warn('注册状态码不能为空!')

  instance.interceptors.response.use(
    (response) => {
      if (response.status === status) handler?.()
      return response
    },
    (error: AxiosError) => {
      if (error.response?.status === status) handler?.()
      return Promise.reject(error)
    }
  )
}

request.register = registerHander
request.setDefaultHost = setDefaultHost
request.setCommonHeader = setCommonHeader
request.instance = instance

export { Post, Get, Put, Delete }
export default request
