// 将16进制的字符串姓名转换为16进制的指令数组，并且包括设置指令和长度
// [0x32, 0x08, 0x01, 0x4c, 0x61, 0x6e, 0x65, 0x5f, 0x32, 0x00]
const hexSetRace = (text: string, type: 'racerName' | 'raceName') => {
  const PM_SET_RACEPARTICIPANT = 0x32
  const arr = []

  for (let i = 0; i < text?.length; i = i + 2) {
    const item = text.slice(i, i + 2)
    arr.push('0x' + item)
  }
  const head =
    type === 'raceName'
      ? [PM_SET_RACEPARTICIPANT, arr.length + 2, 0x00]
      : [PM_SET_RACEPARTICIPANT, arr.length + 2, 0x01]

  //@ts-ignore
  const command = [...head, ...arr, 0x00]

  return command
}
export default hexSetRace
