import coord from '@/assets/coord.png';
import { TRunStatus } from '@/types';
import { FC } from 'react';
import './miles.less';

interface IProps {
  /** 第一名跑了多远 */
  firstUserDistance: number;
  /** 总距离 */
  distanceTotal: number;
  /** 间距 */
  gap?: number;
  /** 列数 */
  columnCount: number;
  runStatus: TRunStatus
}

const Miles: FC<IProps> = ({
  distanceTotal = 100,
  gap = 50, //50间隔的跑道
  columnCount = 1,
  firstUserDistance = 0,
  runStatus }) => {
  const spanNumber = new Array(Math.ceil((distanceTotal / gap))) // 跑道总段数
  const onePageSpanNumber = columnCount === 1 ? 4 : 2// 一列时，一页4段跑道，2列和3列都是2列跑道
  const width = 100 / onePageSpanNumber * spanNumber.length //百分之
  const left = firstUserDistance / distanceTotal * (width - 100)//百分之

  return (
    <div className='miles-container'>
      <div className='points' style={{
        width: `${width}%`,
        left: `-${left}%`,
      }}>
        {
          spanNumber.fill(0)
            .map((i, index) => (
              <div className='span' key={index} style={{
                width: `${100 / spanNumber.length}%`
              }}>
                <div className='split'>
                  <img src={coord} className='coord' />
                  <div className='indicator'>{index * gap}</div>
                  <div className='line' />
                </div>
                {
                  index === spanNumber.length - 1 ? <div className='split last'>
                    <img src={coord} className='coord' />
                    <div className='indicator'>{(index + 1) * gap}</div>
                    <div className='line' />
                  </div> : null
                }
              </div>))
        }
      </div>
    </div>
  );
};

export default Miles;
