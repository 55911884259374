import { EConnect, IConnect } from '@/types'
import { message } from 'antd'

const connect = (
  performanceMonitor: ergometer.PerformanceMonitorUsb
): Promise<IConnect> => {
  return new Promise((resolve) => {
    if (!ergometer.PerformanceMonitorUsb.canUseUsb()) {
      message.error('您的浏览器不支持 WebUSB, 请使用Chrome浏览器')
      resolve(EConnect.OFFLINE)
    }
    performanceMonitor
      .requestDevics()
      .then((devices) => {
        if (devices.length > 0) {
          performanceMonitor.connectToDevice(devices[0]).then(() => {
            resolve(EConnect.CONNECTED)
          })
        }
      })
      .catch(() => {
        resolve(EConnect.OFFLINE)
      })
  })
}

export default connect
