import type { ModalProps } from 'antd';
import { ConfigProvider, Modal } from 'antd';
import type { Locale } from 'antd/es/locale-provider';
import locale_zhCN from 'antd/es/locale/zh_CN';
import React, { cloneElement } from 'react';
import type { Root } from 'react-dom/client';
import { createRoot } from 'react-dom/client';

const nodes: Record<string, Element> = {};
const roots: Record<string, Root> = {};

interface ModalOptions extends ModalProps {
  key?: string;
  prefixCls?: string;
  locale?: Locale;
}

const getDefaultKey = () => Math.random().toString(36).substr(2);

interface BaseDialogProps<T = unknown> {
  onClose?(data?: T): void;
}

const loadModal = async <DialogProps extends BaseDialogProps>(
  children: React.FunctionComponentElement<BaseDialogProps>,
  options: ModalOptions = {},
  id?: string
) => {
  if (Object.keys(nodes).includes(id)) {
    return
  }
  const { key = id || getDefaultKey(), prefixCls, locale, ...props } = options;

  const node = document.createElement('div');
  const root = createRoot(node);
  nodes[key] = node;
  roots[key] = root
  document.body.appendChild(node);

  type DialogRes = Parameters<NonNullable<DialogProps['onClose']>>[0];

  return new Promise<DialogRes>((resolve) => {
    const onClose = (data?: DialogRes) => {
      resolve(data);
      close(key);
      // @ts-ignore, 没有 e
      props.onCancel?.();
    };

    root.render(
      <ConfigProvider prefixCls={prefixCls} locale={locale || locale_zhCN}>
        <Modal {...props} visible footer={null} onCancel={() => onClose()}>
          {cloneElement(children, { onClose, ...props })}
        </Modal>
      </ConfigProvider>
    );
  });
};

const unmount = (key: string) => {
  const node = nodes[key];
  const root = roots[key]
  root.unmount();
  document.body.removeChild(node);
  delete nodes[key];
  delete roots[key];
};

const close = (key?: string) => {
  if (key) return unmount(key);
  Object.keys(nodes).forEach(unmount);
};

const Dialog = {
  load: loadModal,
  close,
};

export default Dialog;
