// 获取pm设备的hw adress
const getHwAdress = (
  performanceMonitor: ergometer.PerformanceMonitorUsb
): Promise<string> => {
  return new Promise(function (resolve, reject) {
    performanceMonitor
      .newCsafeBuffer()
      //@ts-ignore
      .getPmCfg({
        waitForResponse: true,
        address: [0xfd, 0x00], // [destination, source]
        data: [0x82],
        onDataReceived: function (hw: string) {
          // pm很诡异的刷新后第二次获取出现不一样的返回
          const hwAddress = hw.slice(6, 8) === 'fd' ? hw.slice(24, 35) : null
          resolve(hwAddress)
        },
        onError: function () {
          resolve(null)
        },
      })
      .sendExtended()
  })
}

export default getHwAdress
