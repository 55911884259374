import { FC, ReactNode } from 'react';
import { IOffsetRankingItem } from '../type';

interface IProps {
  offsetRankList: IOffsetRankingItem[];
  /** 第几列 */
  column: number;
  renderItem: (item: IOffsetRankingItem, index: number) => ReactNode;
  oneColumnNum: number; //一列有多少条赛道
}

// 排序动画列表
const SortList: FC<IProps> = ({ offsetRankList, renderItem, column, oneColumnNum }) => {
  const oneRowHeight = 40 // 一行的高度
  const list = new Array(oneColumnNum).fill(0)
  const calcTranslateY = (index: number) => {
    return offsetRankList[index]?.offset?.row * oneRowHeight
  }

  return (
    <div className='sort-list' >
      {
        list?.map((item, index) => {
          return <div key={index} className={`sort-item  ${column}-${index}`} style={{
            transform: `translateY(${calcTranslateY(index)}px)`, transition: 'all .2s'
          }}>
            {renderItem(offsetRankList[index], index)}
          </div>
        })
      }
    </div>
  );
};

export default SortList;
