import emitter from '@/utils/emitter'

const onConnected = (
  onSuccess: () => void,
  performanceMonitor: ergometer.PerformanceMonitorUsb
) => {
  performanceMonitor.connectionStateChangedEvent.sub(
    this,
    (
      oldState: ergometer.MonitorConnectionState,
      newState: ergometer.MonitorConnectionState
    ) => {
      if (newState == ergometer.MonitorConnectionState.readyForCommunication) {
        emitter.on('autoAssignSuccess', () => {
          onSuccess()
        })
      }
    }
  )
}

export default onConnected
