import { useStorage } from '@/hooks';
import { STORAGE_KEYS } from '@/types';
import monitor from '@/utils/monitor';
import { useMount } from 'ahooks';

/** 获取划船机连接，并自动监听划船机连接状态，并修改数据 */
const useConnect = () => {
  const [isConnect, setConnect] = useStorage(STORAGE_KEYS.isConnect)
  useMount(() => {
    monitor.onConnected(() => {
      setConnect(true)
    })
  })

  // 刷新页面也要断开连接
  return [isConnect]
}

export default useConnect