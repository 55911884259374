import { Loading } from '@/components';
import { baseUrl } from '@/config';
import { useStorage } from '@/hooks';
import { STORAGE_KEYS } from '@/types';
import getProtoMessage from '@/utils/getProtoMessage';
import { useMount } from 'ahooks';
import * as React from "react";
import { FC } from 'react';
import { useRoutes } from 'react-router-dom';
import './App.less';
import routes from './routes';
import monitor from './utils/monitor';
import request from './utils/request';

const App: FC = () => {
  const [, setConnect] = useStorage(STORAGE_KEYS.isConnect)
  const [token] = useStorage(STORAGE_KEYS.token)

  useMount(() => {
    // 刷新会断开连接
    setConnect(false)
    request.setDefaultHost(baseUrl)
    request.setCommonHeader({ Authorization: token })
    getProtoMessage().then((ProtoMessage) => {
      window.proto = ProtoMessage
    })
    window.addEventListener('beforeunload', function (event) {
      event.returnValue = '确定离开吗'
      return '确定离开吗'
    })
    window.addEventListener('pagehide', async () => {
      await monitor.quitRaceToMain()
    })
  })

  return (
    <>
      {useRoutes(routes.map(({ path, element }) => ({
        path,
        element:
          <React.Suspense fallback={<Loading />}>
            {React.createElement(element)}
          </React.Suspense>
      })))}
    </>
  );
};

export default App;
