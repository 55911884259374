/**
 * 开始比赛
 */
const startRace = (performanceMonitor: ergometer.PerformanceMonitorUsb) => {
  console.log('startRace')
  // 进入开始，关键指令
  performanceMonitor
    .newCsafeBuffer()
    //@ts-ignore 自己封装的指令
    .setPmCfg({
      waitForResponse: true,
      address: [0x01, 0x00], // [destination, source]
      data: [0x1e, 0x01, 0x09],
    })
    .sendExtended()
}

export default startRace
