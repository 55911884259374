import { IRaceItem } from '@/types';
import getDuration from '@/utils/getDuration';
import { useInterval, useUnmount } from 'ahooks';
import dayjs from 'dayjs';
import { FC, useState } from 'react';
import '../index.less';

interface IProps {
  data: IRaceItem;
}


const Bottom: FC<IProps> = ({ data }) => {
  const [elapsedTime, setElapsedTime] = useState('00:00:00')

  const clear = useInterval(() => {
    if (dayjs().isAfter(dayjs(data?.endTimeStamp))) {
      setElapsedTime(getDuration(data?.endTimeStamp - data?.startTimeStamp))
      return
    }
    if (dayjs().isBefore(dayjs(data?.startTimeStamp))) {
      setElapsedTime('00:00:00')
      return
    }
    const duration = dayjs().valueOf() - data?.startTimeStamp
    setElapsedTime(getDuration(duration));
  }, 1000);

  useUnmount(() => {
    clear()
  });

  return (
    <div className='bottom'>
      <div className='left'>
        <div className='goal'>
          <div className='name'>项目目标</div>
          <div className='value'>
            <span>{data?.limitValue}{data?.limitType === 1 ? 'm' : 's'}</span>
            <span className='elapsed-time'>{elapsedTime}</span>
          </div>
        </div>
        <div className='line' />
        <div className='people'>
          <div className='name'>项目报名人数/已参赛人数</div>
          <div className='value'>{data?.applyNum}/{data?.attendNum}</div>
        </div>
      </div>
      <div className='right'>
        <div className='tip'>报名时间：{dayjs(data?.startEnrollTimeStamp).format('YYYY/MM/DD HH:mm:ss')} ～ {dayjs(data?.endEnrollTimeStamp).format('YYYY/MM/DD HH:mm:ss')}</div>
        <div className='tip'>比赛时间：{dayjs(data?.startTimeStamp).format('YYYY/MM/DD HH:mm:ss')} ～ {dayjs(data?.endTimeStamp).format('YYYY/MM/DD HH:mm:ss')}</div>
      </div>
    </div>
  );
};

export default Bottom;
