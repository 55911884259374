import { FC, PropsWithChildren } from 'react';
import './index.less';

const Bg: FC<PropsWithChildren<{}>> = (props) => {
  return (
    <div className='bg-container'>
      {props.children}
    </div>
  );
};

export default Bg;
