const parseRaceData = (data: DataView) => {
  // CSAFE_PM_GET_RACE_DATA 0xC6
  // Byte 0: Tick Time Stamp (MSB)
  // Byte 1: Tick Time Stamp
  // Byte 2: Tick Time Stamp
  // Byte 3: Tick Time Stamp (LSB)
  // Byte 4: Total Race Meters (MSB)
  // Byte 5: Total Race Meters
  // Byte 6: Total Race Meters
  // Byte 7: Total Race Meters (LSB)
  // Byte 8: 500m Pace (MSB)
  // Byte 9: 500m Pace (LSB)
  // Byte 10: Race Elapsed Time (MSB)
  // Byte 11: Race Elapsed Time
  // Byte 12: Race Elapsed Time
  // Byte 13: Race Elapsed Time (LSB)
  // Byte 14: Stroke Rate
  // Byte 15: Race State
  // Byte 16: Percent Battery Level
  // Byte 17: Stroke State
  // Byte 18: Rowing
  // Byte 19: EPM Status
  // Byte 20: Race Operation Type
  // Byte 21: Race Start State
  const index_c6 = 3
  const index_ba = index_c6 + 22 + 2
  const index_bc = index_ba + 4 + 2
  const index_af = index_bc + 4 + 2
  const index_ee = index_af + 4 + 2
  const index_a2 = index_ee + 8 + 2
  const index_c4 = index_a2 + 4 + 2
  const index_b2 = index_c4 + 4 + 2
  const index_8d = index_b2 + 4 + 2

  const tickTime = data.getUint32(0 + index_c6)
  const totalRaceMeters = data.getUint32(4 + index_c6) / 10
  const pace500m = data.getUint16(8 + index_c6) / 100
  const raceElapsedTime = data.getUint32(10 + index_c6) / 100
  const strokeRate = data.getUint8(14 + index_c6)
  const raceState = data.getUint8(15 + index_c6)
  const PercentBatteryLevel = data.getUint8(16 + index_c6)
  const strokeState = data.getUint8(17 + index_c6)
  const rowing = data.getUint8(18 + index_c6)
  const epmStatus = data.getUint8(19 + index_c6)
  const raceOperationType = data.getUint8(20 + index_c6)
  const raceStartState = data.getUint8(21 + index_c6)

  // CSAFE_PM_GET_LAST_SPLITTIME 0xBA
  // Byte 0: Last Elapsed Time / Split (MSB)
  // Byte 1: Last Elapsed Time / Split
  // Byte 2: Last Elapsed Time / Split
  // Byte 3: Last Elapsed Time / Split (LSB)
  const last_split_time = data.getUint32(0 + index_ba)

  //CSAFE_PM_GET_LAST_SPLITDISTANCE 0xBC
  // Byte 0: Last Work Distance/Split (MSB)
  // Byte 1: Last Work Distance/Split
  // Byte 2: Last Work Distance/Split
  // Byte 3: Last Work Distance/Split (LSB)
  const last_split_distance = data.getUint32(4 + index_bc)

  // CSAFE_PM_GET_TOTAL_AVG_500MPACE 0xAF
  // Byte 0: Total Avg Pace / 500m (MSB)
  // Byte 1: Total Avg Pace / 500m
  // Byte 2: Total Avg Pace / 500m
  // Byte 3: Total Avg Pace / 500m (LSB)
  const total_avg_500m_pace = data.getUint32(4 + index_af) / 10000000

  // CSAFE_PM_GET_RACE_BEGINEND_TICKCOUNT 0xEE
  // Byte 0: Race begin tick time, (MSB)
  // Byte 1: Race begin tick time
  // Byte 2: Race begin tick time
  // Byte 3: Race begin tick time (LSB)
  // Byte 4: Race end tick time (MSB)
  // Byte 5: Race end tick time
  // Byte 6: Race end tick time
  // Byte 7: Race end tick time (LSB)
  const race_begin_tick_time = data.getUint32(0 + index_ee)

  const race_end_tick_time = data.getUint32(4 + index_ee)

  //  CSAFE_PM_GET_TOTAL_RESTTIME 0xA2
  // Byte 0: Total Rest Time(MSB)
  // Byte 1: Total Rest Time
  // Byte 2: Total Rest Time
  // Byte 3: Total Rest Time(LSB)
  const total_rest_time = data.getUint32(0 + index_a2)

  // CSAFE_PM_GET_SYNC_DATA 0xC4
  // Byte 0: Sync Data(Float MSB)
  // Byte 1: Sync Data
  // Byte 2: Sync Data
  // Byte 3: Sync Data(Float LSB)
  const sync_data = data.getUint32(0 + index_c4)

  // CSAFE_PM_GET_TOTAL_AVG_CALORIES 0xB2
  // Byte 0: Total Avg Calories (MSB)
  // Byte 1: Total Avg Calories
  // Byte 2: Total Avg Calories
  // Byte 3: Total Avg Calories (LSB)
  const total_avg_calories = data.getUint32(0 + index_b2) / 1000000

  // CSAFE_PM_GET_WORKOUTSTATE 0x8D
  // Byte 0: Workout State
  const workout_state = data.getUint8(0 + index_8d)

  const race_data = {
    tickTime,
    totalRaceMeters,
    pace500m,
    raceElapsedTime,
    strokeRate,
    raceState,
    PercentBatteryLevel,
    strokeState,
    rowing,
    epmStatus,
    raceOperationType,
    raceStartState,
    last_split_time,
    last_split_distance,
    total_avg_500m_pace,
    race_begin_tick_time,
    race_end_tick_time,
    total_rest_time,
    sync_data,
    total_avg_calories,
    workout_state,
  }

  return race_data
}

export default parseRaceData
