import { IRoomType } from '@/types';
import { HeadType, Message, RowDataSubmitRequest } from '@/types/proto';

/** 提交运动数据请求消息 —— RowDataSubmitRequest */
const useSubmit = ({
  roomType,
}: { roomType: IRoomType }) => {
  const sendSubmitSocket = (rowData: Partial<RowDataSubmitRequest>) => {
    if (roomType === 'racing' || roomType === 'prepare') {
      const data = {
        type: HeadType.ROW_DATA_SUBMIT_REQUEST,
        rowDataSubmitRequest: rowData
      } as Message
      console.log('sendSubmitSocket', data)
      window.webSocketIns?.sendProtoMessage(data)
    }
  }

  return [sendSubmitSocket]
}

export default useSubmit