import emitter from '@/utils/emitter'
import { genQueue, sendQueue } from './pmQueue'

/** 自动分配测力计编号
 */
const autoAssign = (performanceMonitor: ergometer.PerformanceMonitorUsb) => {
  return new Promise(function (resolve, reject) {
    const { queue, queuePush } = genQueue()
    queuePush('f0 01 00  76 09 22 07  0b 02 01 0b  05 07 e6')
    queuePush(
      'f0 01 00  76 10 21 08  ff ff 38 40  00 05 00 01' +
        ' ' +
        '3d 04 00 00  00 00'
    )
    queuePush('f0 01 00  76 03 27 01  00')

    queuePush('f0 01 00  76 05 25 01  01 9d 83')
    queuePush('f0 01 00  7e 02 9d 83')
    // 下面这个指令偶尔貌似会造成划船机断联
    queuePush('f0 ff 00  76 03 1e 01  03')
    queuePush('f0 ff 00  76 04 13 02  02 06')
    queuePush('f0 ff 00  76 03 2c 01  01')
    //
    queuePush('f0 ff 00  76 03 1e 01  04')
    queuePush('f0 ff 00  76 04 13 02  02 07')
    queuePush('f0 01 00  7e 01 87 f8  f2')
    queuePush('f0 01 00  76 04 0b 02  01 00')
    queuePush('f0 01 00  7e 04 51 02  01 01')
    //
    queuePush('f0 ff 00  76 04 13 02  02 0e')
    sendQueue(performanceMonitor, queue).then(() => {
      console.log('auto-assign success')
      //@ts-ignore
      resolve()
      emitter.emit('autoAssignSuccess')
    })
  })
}

export default autoAssign
