import { IStorage } from '@/types';
import { useState } from 'react';

const Storage = {
  set: (key: keyof IStorage, val: any) => {
    localStorage.setItem(key, JSON.stringify(val))
  },
  remove: (key: keyof IStorage) => {
    localStorage.removeItem(key)
  },
  clear: () => {
    localStorage.clear()
  },
  get: (key: keyof IStorage) => {
    const res = localStorage.getItem(key)
    if (res !== 'undefined' && res) {
      return JSON.parse(res)
    } else {
      return {}
    }
  },
}

/** 设置存储修改本地存 */
const useStorage = <T extends keyof IStorage>(key: T): [IStorage[T], (val: IStorage[T]) => void] => {
  const initialVal = Storage.get(key)
  const [state, setState] = useState<IStorage[T]>(initialVal);

  const setStorage = (val: IStorage[T]) => {
    Storage.set(key, val)
    setState(val)
  }

  return [state, setStorage]
}

export default useStorage