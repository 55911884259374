import { TRunStatus } from '@/types';
import { RankingItem } from '@/types/proto';
import { FC } from 'react';
import Miles from './components/Miles';
import RunwaysList from './components/RunwaysList';
import './index.less';
import { genRankList } from './utils';

interface IProps {
  /** 排名数据 */
  rankList: Partial<RankingItem>[];
  /** 总距离 */
  distanceTotal: number;
  runStatus: TRunStatus
  /**
 * 限制类型，1限制里程M, 2限制时间秒内最大距离，3限制时间秒内最大卡路里，4限制卡路里
 */
  limitType: number;
}

const Board: FC<IProps> = ({ rankList, distanceTotal = 100, runStatus, limitType }) => {
  const offsetRankList = genRankList(rankList);
  const oneColumnNum = 21; // 一列有多少条赛道
  const column = new Array(Math.max(Math.ceil(rankList?.length / oneColumnNum), 1)).fill(0) // 一共有多少列
  // 第一名跑了多远
  const firstUserDistance = offsetRankList?.find((i) => i.ranking === 1)?.distance || 0

  return (
    <div className='board'>
      <div className='runway-container'>
        {
          column.map((i, columnIndex) => (
            <div className='runway-column' key={columnIndex}>
              <Miles
                firstUserDistance={firstUserDistance}
                runStatus={runStatus}
                columnCount={column?.length}
                distanceTotal={distanceTotal} />
              <RunwaysList
                distanceTotal={distanceTotal}
                firstUserDistance={firstUserDistance}
                runStatus={runStatus}
                columnIndex={columnIndex}
                oneColumnNum={oneColumnNum}
                offsetRankList={offsetRankList.slice(columnIndex * oneColumnNum, (columnIndex + 1) * oneColumnNum)}
                columnCount={column?.length}
                limitType={limitType}
              />
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default Board;
