import { HeadType, Message, RaceUserOfflineNotification } from '@/types/proto';
import emitter from '@/utils/emitter';
import { useState } from 'react';

/** 下线通知消息 —— RaceUserOnlineNotification */
const useOffline = () => {
  const [raceUserOffline, setRaceUserOffline] = useState<RaceUserOfflineNotification>(null)
  emitter.on('onMessage', (message: Message) => {
    if (message?.type === HeadType.RACE_USER_OFFLINE) {
      console.log('on raceUserOffline', message)
      setRaceUserOffline(message?.raceUserOffline)
    }
  });

  return raceUserOffline
}

export default useOffline