// 将10进制数字转换为以空格分割的4个字符的数组
const toHexStringArr = (data: number) => {
  const str = data.toString(16)
  if (str.length === 2) {
    return [0x00, '0x' + str]
  } else if (str.length === 3) {
    return ['0x0' + str.slice(0, 1), '0x' + str.slice(1)]
  } else if (str.length === 4) {
    return ['0x0' + str.slice(0, 2), '0x' + str.slice(2)]
  } else if (str.length === 5) {
    return [
      '0x0' + str.slice(0, 1),
      '0x' + str.slice(1, 3),
      '0x' + str.slice(3),
    ]
  }
}

export default toHexStringArr
