import Spin from './Spin';

const Loading = () => {
  return (
    <div style={{ position: 'fixed', display: 'flex', width: '100%', height: '100%' }}>
      <Spin style={{ margin: 'auto' }} />
    </div>
  );
};

export default Loading
