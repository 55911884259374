import { TRunStatus } from '@/types';
import { FC } from 'react';
import '../index.less';
import { IOffsetRankingItem } from '../type';
import Arrive from './Arrive';
import Rowing from './Rowing';
import SortList from './SortList';

interface IProps {
  oneColumnNum: number;  //一列有多少条赛道
  /** 总距离 */
  distanceTotal: number;
  columnIndex: number;
  columnCount: number; // 一共有几列
  offsetRankList: IOffsetRankingItem[];
  runStatus: TRunStatus;
  firstUserDistance: number; //第一名跑了多远
  /**
* 限制类型，1限制里程M, 2限制时间秒内最大距离，3限制时间秒内最大卡路里，4限制卡路里
*/
  limitType: number;
}

const RunwaysList: FC<IProps> = (
  { oneColumnNum, distanceTotal,
    columnIndex, runStatus,
    columnCount, offsetRankList, firstUserDistance, limitType }) => {
  return (
    <SortList
      offsetRankList={offsetRankList}
      oneColumnNum={oneColumnNum}
      column={columnIndex}
      renderItem={(rankItem, itemIndex) => {
        const isArrived = rankItem?.distance === distanceTotal;
        const mode = (itemIndex + (rankItem?.offset?.row || 0)) % 2 === 0 ? 'light' : 'dark'
        if (!isArrived && runStatus !== 'end') {
          return <Rowing
            columnIndex={columnIndex}
            columnCount={columnCount}
            row={itemIndex}
            firstUserDistance={firstUserDistance}
            distanceTotal={distanceTotal}
            rankItem={rankItem}
            mode={mode} />
        } else {
          return <Arrive
            rankItem={rankItem}
            limitType={limitType}
            mode={mode} />
        }
      }} />
  );
};

export default RunwaysList;
