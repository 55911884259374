export interface ILoginRes {
  /**
   * 比赛项目
   */
  attendRaceItems: IRaceItem[]
  /**
   * 参赛者信息
   */
  rowUser: IRowUser
  /**
   * socket链接域名，建立websocket的url，如：new WebSocket(url)
   */
  socketDomain: string
  /**
   * 鉴权token
   */
  token: string
}

export interface IRaceItem {
  /**
   * 报名人数
   */
  applyNum: number
  /**
   * 参与人数
   */
  attendNum: number
  /**
   * 比赛开始倒计时，单位秒
   */
  countDownSeconds: number
  /**
   * 赛事项目的结束时间，毫秒数
   */
  endTimeStamp: number
  /**
   * 赛事项目名
   */
  itemName: string
  /**
   * 参赛类型，1：个人赛 2：团体赛，默认1
   */
  joinType: number
  /**
   * 限制类型，1限制里程M, 2限制时间秒内最大距离，3限制时间秒内最大卡路里，4限制卡路里
   */
  limitType: number
  /**
   * 限制类型的值，项目的目标值
   */
  limitValue: number
  /**
   * 赛事id
   */
  raceId: number
  /**
   * 赛事项目id
   */
  raceItemId: number
  /**
   * 赛事名
   */
  raceName: string
  /**
   * 分段数量
   */
  splitNumber: number
  /**
   * 赛事项目的开始时间，毫秒数
   */
  startTimeStamp: number
  /**
   * 比赛状态，0.未开始 1.报名中 2.报名结束 3.准备中 4.进行中 5.已结束
   */
  status: number
  endEnrollTimeStamp: number
  startEnrollTimeStamp: number
  /** 比赛名称的16进制 */
  pmDisplayName: string
  /** 比赛结束倒计时，单位秒 */
  endCountDownSeconds: number
  /**进入比赛类型：1、练习模式，2、准备比赛，3、开始比赛 */
  enterMode: number
}

/**
 * 参赛者信息
 */
export interface IRowUser {
  /**
   * 参赛者头像
   */
  avatar?: string
  /**
   * 参赛者昵称
   */
  nickName: string
  /**
   * 参赛者uid
   */
  uid: number
  pmDisplayRacer: string
}

/** 比赛状态 */
/** 0.未开始 1.报名中 2.报名结束 3.准备中 4.进行中 5.已结束 */
export enum EStatus {
  notStart = 0,
  enroll = 1,
  enrollEnd = 2,
  prepare = 3,
  continue = 4,
  end = 5,
}

/** 比赛进行详细的状态 */
/** 未开始、准备中、进行中但是可以参赛，进行中但是不能参赛只能观赛，已结束 */
export enum ERaceDetailStatus {
  NOTSTART = 'not_start',
  PREPARE = 'prepare',
  RACING_CAN_JOIN = 'racing_can_join',
  RACING_CAN_WATCH = 'racing_can_watch',
  END = 'end',
}
/** 比赛进行详细的状态 */
/** 未开始、准备中、进行中但是可以参赛，进行中但是不能参赛只能观赛，已结束 */
export type TRaceDetailStatus =
  | 'not_start'
  | 'prepare'
  | 'racing_can_join'
  | 'racing_can_watch'
  | 'end'

export enum EConnect {
  OFFLINE = 'offline',
  CONNECTING = 'connecting',
  CONNECTED = 'connected',
}

/** 连接状态 */
export type IConnect = 'offline' | 'connecting' | 'connected'

export interface IStorage {
  attendRaceItems: IRaceItem[]
  rowUser?: IRowUser
  token: string
  socketDomain: string
  isConnect: boolean
  serialnum: string | number
}

export enum STORAGE_KEYS {
  rowUser = 'rowUser',
  token = 'token',
  socketDomain = 'socketDomain',
  isConnect = 'isConnect',
  serialnum = 'serialnum',
}

/** 比赛或者练习跑道状态 */
export type TRunStatus = 'setting' | 'ready' | 'racing' | 'end'
/** 比赛或者练习跑道状态 */
export enum ERunStatus {
  /** 设置比赛 */
  SETTING = 'setting',
  /** 设置完成 */
  READY = 'ready',
  /** 比赛或者练习中 */
  RACING = 'racing',
  /** 结束 */
  END = 'end',
}

/** 房间类型 */
export type IRoomType = 'test' | 'prepare' | 'racing' | 'watch'
