import { RowDataSubmitRequest } from '@/types/proto'
import monitor from '@/utils/monitor'

const instance = {
  interval: 1000, // 每1000毫秒获取一次数据
  //@ts-ignore
  timer: null,
  reset: function () {
    clearInterval(window.getPm5Data_timer)
    window.getPm5Data_timer = null
  },
  start: function (
    distanceTotal: number,
    setRaceData: (val: Partial<RowDataSubmitRequest>) => void
  ) {
    this.reset()
    window.getPm5Data_timer = setInterval(async () => {
      console.log('getPm5Data timer')
      const race_data = await monitor.getRaceData()
      if (race_data?.totalRaceMeters <= distanceTotal) {
        setRaceData({
          distance: race_data?.totalRaceMeters,
          elapsedTimeSecs: race_data?.raceElapsedTime,
          calories: race_data?.total_avg_calories,
          pace: race_data?.pace500m,
          spm: race_data?.strokeRate,
        })
      }
    }, this.interval)
  },
}

const getPm5Data = () => {
  if (window.getPm5Data) {
    return window.getPm5Data
  } else {
    window.getPm5Data = instance
    return instance
  }
}

export default getPm5Data
