import no1 from '@/assets/no1.png';
import no2 from '@/assets/no2.png';
import no3 from '@/assets/no3.png';
import wave1 from '@/assets/wave1.png';
import wave2 from '@/assets/wave2.png';
import { RankingItem } from '@/types/proto';
import { FC } from 'react';
import './rowing.less';

interface IProps {
  mode: 'light' | 'dark';
  rankItem?: Partial<RankingItem>
  /**
  * 限制类型，1限制里程M, 2限制时间秒内最大距离，3限制时间秒内最大卡路里，4限制卡路里
  */
  limitType: number;
}

// 到达终点
const Arrive: FC<IProps> = ({ mode, rankItem, limitType }) => {
  const renderRank = () => {
    if (rankItem?.ranking > 3) {
      return <div className='rank-normal'>{rankItem?.ranking}</div>
    } else {
      const src = rankItem?.ranking == 1 ? no1 : (rankItem?.ranking == 2 ? no2 : no3)
      return <img className='rank-img' src={src} />
    }
  }
  return (
    <div className={`run-way ${mode} arrive`} >
      {
        <img src={mode === 'light' ? wave1 : wave2} className='wave-img' />
      }
      {
        rankItem
          ? <div className='arrive-item'>
            <div className='left'>
              {renderRank()}
              <div className='time'>{limitType === 1 ? rankItem?.timeStr : rankItem?.distance}</div>
              <div className='address'>{rankItem?.location}</div>
              <div className='nick-name'>{rankItem?.nickName}</div>
            </div>
            <div className='right'>
              <div className='boat-arrive'>
                <span className='rank'>{rankItem?.laneNum}</span>
              </div>
              <div className='paceStr'>{rankItem?.paceStr}</div>
            </div>
          </div>
          : null
      }
    </div>
  );
};

export default Arrive;
