import { HeadType, Message, RaceUserOnlineNotification } from '@/types/proto';
import emitter from '@/utils/emitter';
import { useState } from 'react';

/** 上线通知消息 —— RaceUserOnlineNotification */
const useOnline = () => {
  const [raceUserOnline, setRaceUserOnline] = useState<RaceUserOnlineNotification>(null)
  emitter.on('onMessage', (message: Message) => {
    if (message?.type === HeadType.RACE_USER_ONLINE) {
      console.log('on userOnline', message)
      setRaceUserOnline(message?.raceUserOnline)
    }
  });

  return raceUserOnline
}

export default useOnline