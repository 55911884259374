import { LoadingOutlined } from '@ant-design/icons';
import type { SpinProps } from 'antd';
import { Spin as AntdSpin } from 'antd';
import { FC } from 'react';

const Spin: FC<SpinProps> = (props) => {
  return (
    <AntdSpin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} delay={500} {...props} />
  );
};

export default Spin;
