import { IRaceItem } from '@/types'
import { Get, Post } from '@/utils/request'

/** 获取登录用户参与的赛事 */
export const getRaceListAPI = async () => {
  return await Get<IRaceItem[]>('/attend_race')
}

/**
 * pm认证密码
  POST /client/pm_auth
  接口ID：50157524
  接口地址：https://www.apifox.cn/web/project/1262825/apis/api-50157524
 */
export const getPmAuthAPI = async (serialnum: string | number) => {
  return await Post<{ serialnum: string; authenPw: string }>('/pm_auth', {
    serialnum,
  })
}
