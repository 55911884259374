import parseRaceData from './parseRaceData'

const getRaceData = (
  performanceMonitor: ergometer.PerformanceMonitorUsb
): Promise<ReturnType<typeof parseRaceData>> => {
  return new Promise((resolve, reject) => {
    performanceMonitor
      .newCsafeBuffer()
      // @ts-ignore 自己封装的指令
      .setPmCfg({
        waitForResponse: true,
        address: [0x01, 0x00], // [destination, source]
        data: [
          0x33, 0x1d, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00,
          0x00, 0x00, 0x00, 0x01, 0x01, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00,
          0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0xc6, 0xba,
          0xbc, 0xaf, 0xee, 0xa2, 0xd0, 0xc4, 0xb2, 0x8d,
        ],
        onDataReceived: function (data: DataView) {
          const race_data = parseRaceData(data)
          // console.log('比赛数据', race_data)
          resolve(race_data)
        },
      })
      .sendExtended()
  })
}

export default getRaceData
