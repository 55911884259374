import getCheckSum from '../getCheckSum'

export const sendQueue = (
  performanceMonitor: ergometer.PerformanceMonitorUsb,
  queue: string[][]
) => {
  return new Promise((resolve, reject) => {
    // 发送原始数据
    const sendRaw = (queueIndex: number) => {
      let sendBytesIndex = 0
      let arr = queue[queueIndex]
      const checksum = getCheckSum(arr)

      const bytesToSend = arr.concat([`0x${checksum}`, '0xf2'])

      //continue while not all bytes are send
      while (sendBytesIndex < bytesToSend.length) {
        //prepare a buffer with the data which can be send in one packet
        let bufferLength = bytesToSend.length - sendBytesIndex
        let buffer = new ArrayBuffer(120) //start and end and
        let dataView = new DataView(buffer)
        let bufferIndex = 0
        while (bufferIndex < bufferLength) {
          //@ts-ignore
          dataView.setUint8(bufferIndex, bytesToSend[sendBytesIndex])
          sendBytesIndex++
          bufferIndex++
        }

        performanceMonitor
          //@ts-ignore
          .driver_write(dataView)
          .then(() => {
            if (sendBytesIndex >= bytesToSend.length) {
              if (queueIndex < queue.length - 1) {
                setTimeout(() => {
                  sendRaw(queueIndex + 1)
                }, 500)
              } else {
                //@ts-ignore
                resolve()
              }
            }
          })
          .catch(function (e) {
            sendBytesIndex = bytesToSend.length //stop the loop
            reject(e)
          })
      }
    }
    sendRaw(0)
  })
}

export const genQueue = () => {
  const queue: string[][] = []
  // 发送结束指令f2之前的所有指令
  function queuePush(text: string) {
    const array = text.split(' ').filter(Boolean)
    const hexRawCommand = array.map((i) => '0x' + i)
    queue.push(hexRawCommand)
  }

  return {
    queue,
    queuePush,
  }
}
