import { RankingItem } from '@/types/proto'
import { IOffsetRankingItem } from './type'

/** 生成排行数据 */
export const genRankList = (
  rankList: Partial<RankingItem>[]
): IOffsetRankingItem[] => {
  return rankList.map((item, index) => {
    return {
      ...item,
      offset: {
        column: 0,
        row: item.ranking - index - 1,
      },
    }
  })
}
